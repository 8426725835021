<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>Stripe Settings</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                Unicorn Springs partners with Stripe for secure payments.
                            </p>
                            <p class="mb-0 mt-2">
                                When customers pay at your online store hosted by Unicorn Springs, the payment information is collected and processed by Stripe, which then subtracts transaction fees and deposits the remaining amount into your bank account.
                            </p>

                            <template v-if="stripeAccountId">
                            <p class="mb-0 pb-0 mt-6">
                                <span>
                                <font-awesome-icon :icon="['fas', 'check']" class="green--text text--darken-2 mr-2"></font-awesome-icon>
                                <span class="font-weight-medium">Stripe account is connected.</span>
                                </span>
                                <!-- TODO: show stripe account name, by asking server to get it from stripe with API call -->
                                <!-- Stripe account id: {{ stripeAccountId }} -->
                                <!-- <a @click.prevent="connect"><span style="border-bottom: 1px solid">Manage your Stripe account</span></a> -->
                                <!-- <v-btn icon color="green darken-2" @click="dialogEditHostname = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                </v-btn> -->
                            </p>
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewHostnameURL" target="_blank">{{ viewHostnameURL }}</TextLink>
                            </p> -->
                            </template>

                            <template v-if="!stripeAccountId">
                            <p class="mb-0 pb-0 mt-6">
                                <a @click.prevent="connect"><span style="border-bottom: 1px solid">Set up your Stripe account to accept payments</span></a>
                            </p>
                            <p class="mb-0 pb-0">
                                <!-- <v-btn icon color="green darken-2" @click="dialogEditHostname = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"></font-awesome-icon>
                                </v-btn> -->
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-alert type="info" class="mt-6">
                        <p class="mb-0 font-weight-bold">Internet safety</p>
                        <p class="mb-0">We will <em>never</em> ask you for your Stripe username or password.
                        <p class="mb-0">We will <em>never</em> send you urgent emails about your Stripe account.</p>
                    </v-alert>

                    <!-- <v-dialog v-model="dialogEditHostname" max-width="600">
                        <v-card class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="green--text text--darken-2">Set Custom Hostname</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <p>Changes to the custom hostname may break existing links. If you change your hostname, you should also set up a redirect from the prior hostname to the new one for customers that may have it bookmarked.</p>
                            </v-card-text>
                            <v-form @submit.prevent="editHostname" @keyup.enter.native="editHostname" class="px-5 mt-5">
                                <v-text-field
                                    ref="editableHostnameInput"
                                    v-model="editableHostname"
                                    label="Hostname"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="editHostname" :disabled="!isEditHostnameFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditHostname = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // TextLink,
    },
    data: () => ({
        organization: null,
        brandprofile: null,
        stripeAccountId: null,
        error: null,

        // dialogEditHostname: false,
        // editableHostname: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        // isEditHostnameFormComplete() {
        //     return this.editableHostname;
        // },
    },
    watch: {
        // dialogEditHostname(newValue) {
        //     if (newValue) {
        //         this.editableHostname = this.brandprofile;
        //         this.$nextTick(() => {
        //             setTimeout(() => { this.$activateInput('editableHostnameInput'); }, 1);
        //         });
        //     }
        // },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadSetting(name) {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.get({ name });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                return response?.content ?? null;
            } catch (err) {
                console.error('failed to load setting', err);
                return null;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        async loadBrandProfile() {
            this.brandprofile = await this.loadSetting('brandprofile');
        },
        async loadStripeAccountId() {
            this.stripeAccountId = await this.loadSetting('stripe_account_id');
        },
        async connect() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { connectStripeAccount: true });
                const response = await this.$client.organization(this.$route.params.organizationId).stripe.connect({ intent: 'settings' });
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://connect.stripe.com/setup/s/CAFwVkHysDCz","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('failed to get link to stripe', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Stripe' });
            } finally {
                this.$store.commit('loading', { connectStripeAccount: false });
            }
        },
        // async manage() {
        //     try {
        //         if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
        //             return;
        //         }
        //         this.submitFormTimestamp = Date.now();
        //         this.error = false;
        //         this.$store.commit('loading', { manageStripeAccount: true });
        //         const response = await this.$client.organization(this.$route.params.organizationId).stripe.manage();
        //         // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://connect.stripe.com/setup/s/CAFwVkHysDCz","redirect_max_seconds":300}
        //         if (response?.redirect) {
        //             window.location.href = response.redirect;
        //         }
        //     } catch (err) {
        //         console.error('failed to get link to stripe', err);
        //         this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Stripe' });
        //     } finally {
        //         this.$store.commit('loading', { manageStripeAccount: false });
        //     }
        // },
    },
    mounted() {
        this.loadOrganization();
        this.loadBrandProfile();
        this.loadStripeAccountId();
    },
};
</script>
